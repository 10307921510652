import React from "react"
import theme from "theme"
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Послуги | CMG Marketing</title>
        <meta
          name={"description"}
          content={"Перетворення бачень у реальність"}
        />
        <meta property={"og:title"} content={"Послуги | CMG Marketing"} />
        <meta
          property={"og:description"}
          content={"Перетворення бачень у реальність"}
        />
        <meta
          property={"og:image"}
          content={"https://echopeakrip.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://echopeakrip.com/img/0433463.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://echopeakrip.com/img/0433463.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://echopeakrip.com/img/0433463.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://echopeakrip.com/img/0433463.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://echopeakrip.com/img/0433463.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://echopeakrip.com/img/0433463.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0 80px 0" quarkly-title="Cards-7">
        <Override
          slot="SectionContent"
          width="95%"
          min-width="95%"
          max-width="100%"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          lg-flex-direction="column"
          grid-gap="24px"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="30%"
            lg-width="auto"
            lg-margin="0px 0px 50px 0px"
          >
            <Text
              margin="0px 0px 8px 0px"
              text-align="left"
              color="--dark"
              font="--base"
              letter-spacing="2px"
              text-transform="uppercase"
            >
              Маркетингове агентство CMG
            </Text>
            <Text
              margin="0px 0px 70px 0px"
              font="normal 500 42px/1.2 --fontFamily-sans"
              text-align="left"
              md-margin="0px 0px 40px 0px"
              sm-font="normal 600 42px/1.2 --fontFamily-sans"
              color="--darkL1"
            >
              Наші послуги
            </Text>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="grid"
            grid-template-columns="repeat(2, 1fr)"
            grid-gap="36px 24px"
            lg-grid-template-columns="repeat(2, 1fr)"
            sm-grid-template-columns="1fr"
            md-grid-template-columns="1fr"
            md-grid-gap="36px 24px"
            width="70%"
            lg-width="auto"
          >
            <Box
              min-width="100px"
              min-height="100px"
              border-color="--color-darkD2"
              display="flex"
              flex-direction="column"
              justify-content="space-between"
              border-width={0}
            >
              <Image
                src="https://echopeakrip.com/img/6.jpg"
                display="block"
                margin="0px 0px 30px 0px"
              />
              <Text
                margin="0px 0px 8px 0px"
                color="--dark"
                font="--headline3"
                text-transform="uppercase"
                letter-spacing="2px"
              >
                Креативний дизайн
              </Text>
              <Box min-width="100px" min-height="100px">
                <Text margin="0px 0px 15px 0px" font="--lead" color="#133957">
                  Графічний дизайн: розробка привабливої ​​графіки для всіх
                  ваших маркетингових потреб.
                  <br />
                  <br />
                  Друковані матеріали: створення брошур, флаєрів та плакатів.
                  <br />
                  <br />
                  Цифрова графіка: розробка банерів, графіки для соціальних
                  мереж тощо.
                  <br />
                  <br />
                  Дизайн веб-сайту: створення зручних веб-сайтів, які стимулюють
                  залучення.
                </Text>
              </Box>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              border-width={0}
              border-style="solid"
              border-color="--color-darkD2"
              display="flex"
              flex-direction="column"
              justify-content="space-between"
            >
              <Image
                src="https://echopeakrip.com/img/5.jpg"
                display="block"
                margin="0px 0px 30px 0px"
              />
              <Text
                margin="0px 0px 8px 0px"
                color="--dark"
                font="--headline3"
                text-transform="uppercase"
                letter-spacing="2px"
              >
                Стратегічне планування
              </Text>
              <Box min-width="100px" min-height="100px">
                <Text margin="0px 0px 15px 0px" font="--lead" color="#133957">
                  Аналіз ринку: розуміння вашої цільової аудиторії та
                  конкурентів для формування вашої маркетингової стратегії.
                  <br />
                  <br />
                  Постановка цілей: Визначення чітких, досяжних цілей, якими
                  керуватимете своїми маркетинговими зусиллями.
                  <br />
                  <br />
                  Планування кампанії: розробка комплексних маркетингових
                  кампаній, які відповідають вашим бізнес-цілям.
                </Text>
              </Box>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              border-width={0}
              border-style="solid"
              border-color="--color-darkD2"
              display="flex"
              flex-direction="column"
            >
              <Image
                src="https://echopeakrip.com/img/7.jpg"
                display="block"
                margin="0px 0px 30px 0px"
              />
              <Text
                margin="0px 0px 8px 0px"
                color="--dark"
                font="--headline3"
                text-transform="uppercase"
                letter-spacing="2px"
              >
                Цифровий маркетинг
              </Text>
              <Box min-width="100px" min-height="100px">
                <Text margin="0px 0px 15px 0px" font="--lead" color="#133957">
                  Пошукова оптимізація (SEO): покращення видимості вашого
                  веб-сайту в пошукових системах, щоб залучити більше звичайного
                  трафіку.
                  <br />
                  <br />
                  Дослідження ключових слів: визначення найкращих ключових слів
                  для вашого бізнесу.
                  <br />
                  <br />
                  Маркетинг у пошукових системах (SEM): використання платної
                  реклами для збільшення трафіку та конверсій.
                  <br />
                  <br />
                  Кампанії з оплатою за клік (PPC): керування витратами на
                  рекламу для максимізації рентабельності інвестицій.
                </Text>
              </Box>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              border-width={0}
              border-style="solid"
              border-color="--color-darkD2"
              display="flex"
              flex-direction="column"
            >
              <Image
                src="https://echopeakrip.com/img/4.jpg"
                display="block"
                margin="0px 0px 30px 0px"
              />
              <Text
                margin="0px 0px 8px 0px"
                color="--dark"
                font="--headline3"
                text-transform="uppercase"
                letter-spacing="2px"
              >
                Розвиток бренду
              </Text>
              <Box min-width="100px" min-height="100px">
                <Text margin="0px 0px 15px 0px" font="--lead" color="#133957">
                  Стратегія бренду: визначення бачення, місії та цінностей
                  вашого бренду.
                  <br />
                  <br />
                  Візуальна ідентичність: створення цілісної візуальної
                  ідентичності, яка відображає індивідуальність вашого бренду.
                  <br />
                  <br />
                  Дизайн логотипу: створення унікального логотипу, що
                  запам’ятовується.
                  <br />
                  <br />
                  Палітра кольорів: вибір кольорів, які представляють
                  ідентичність вашого бренду.
                  <br />
                  <br />
                  Типографіка: вибір шрифтів, які відповідають голосу вашого
                  бренду.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Cta />
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
